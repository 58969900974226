<template>
  <v-card>
    <v-card-title>
      <span class="text-h6">{{ $t("labels.note") }}</span>
      <v-spacer></v-spacer>
      <v-btn
        small
        color="success"
        :disabled="!note"
        outlined
        @click="submitNote"
        class="mr-2"
      >
        {{ $t("labels.confirm") }}
      </v-btn>
      <v-btn small color="error" outlined @click="closeDialog">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            class="c-input-xs"
            v-model="note"
            type="text"
            :label="$t('labels.note')"
            dense
            outlined
            clearable
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
export default {
  name: "FindGoodsLostItem",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    items: [],
    isLoading: false,
    note: null,
    goodsSelected: null,
  }),
  computed: {},
  mounted() {
    this.getList();
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    async submitNote() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      try {
        await httpClient.post("/find-goods-manager-add-note", {
          id_find_goods: this.item.id,
          note: this.note,
        });
        this.isLoading = false;
        this.closeDialog();
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>

<style scoped></style>
